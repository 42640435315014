.LandingPage {
  background-image: url('splashscreen.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.LandingPage-logo {
  position: fixed;
  bottom: 45px;
  right: 34px;
}

.LandingPage-content {
  background: white;
  width: 538px;
  height: 258px;
  margin: auto;
  margin-left: -269px;
  margin-top: -129px;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
}
